import React, { Component } from 'react'
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import LeftNavBar from '../../../containers/navbar'
import { navigate } from '@reach/router'
import { Cache } from "aws-amplify";
import Query from '../../../containers/netsclick/query'
import { FormGroup, Col } from 'reactstrap'
import { isLoggedIn } from '../../../services/auth';
import {  updateIntegrationValues, getIntegrationStatus } from '../../../services/api'
import Merchantcard from '../../../containers/netsclick/merchantcard';

const MATERIALS_PAGE_FLAG = '1'
const MATERIALS_SPECS_FLAG = '3'

export default class Material extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: true,
            selectedApp: null,
        }
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
      
    }
    async componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;

        if (application) {
          let data = await getIntegrationStatus({
            email: application.email,
            netsclickAppID: application.nc_app_id
          })
          let materialStatus = data && data.length >= 1 ? data[0] : null
          materialStatus = materialStatus && materialStatus.length >= 0 ? materialStatus[0] : null
          if(!materialStatus || !materialStatus.materials){
            this.updateIntegrationInformation(MATERIALS_PAGE_FLAG)
          }
        }

    }
    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
    }
    redirectPage(pageId) {
        navigate(`/product/netsclick/${pageId}`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    async updateIntegrationInformation(flag) {
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        if (application) {
            let inputObj = null
           
            inputObj = {
                type: flag,
                email: application.email,
                netsclickAppID: application.nc_app_id
            }

            await updateIntegrationValues(inputObj)
        }

    }
    render() {
        let { fetched } = this.state
        if (!fetched) {
            return <div class="loading"></div>
        }
        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>

                    <FormGroup row>
                        <Col sm={9}>
                            <h4><b>Welcome to NETS Click developer portal page.</b></h4>
                        </Col>
                        <Merchantcard />
                    </FormGroup>
                </div>
                <FormGroup row >
                    <Col sm={2}>
                        <LeftNavBar menu='materials' />
                    </Col>
                    <Col sm={10} >
                        <div>
                            <h2><b>Materials</b></h2>
                            <h5 className='leftAligned'><b>Overview</b></h5>
                            <ul>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/int-onboarding-flow.pdf"} download><u><b>Integration Onboarding Flow</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/high-level-flow-diagram.docx"} download><u><b>High Level Diagram Flows</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/merchant-token-key.pdf"} download><u><b>Merchant Token Key Concept</b></u></a></li></div>
                            </ul>
                            <h5 className='leftAligned'><b>SDK Files & Integration Documents</b></h5>
                            <ul>
                                <div className='hyperlink leftAligned' onClick={() => this.redirectPage('mobilesdk')}><li><u><b>Mobile SDK Files</b></u></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"https://drive.google.com/drive/folders/1Aq329oYNXeXL5qFscB61DtOvWEANVmwb"} target='_blank'><u><b>Mobile Server Certificate(IOS)</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"https://drive.google.com/drive/folders/1upxP_1G36EvKhiVEsEld0QkJpI07ghY5"} target='_blank'><u><b>Mobile Server Certificate(Android)</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/mobile-sdk-document-ios.pdf"} download><u><b>Mobile SDK Document(IOS)</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/mobile-sdk-document-android.pdf"} download><u><b>Mobile SDK Document(Android)</b></u></a></li></div>
                                <div className='hyperlink leftAligned' onClick={() => this.updateIntegrationInformation(MATERIALS_SPECS_FLAG)}><li><a href={"/assets/netsclick/materials/merchant-host-api-spec.pdf"} download><u><b>Merchant Host JSON API specs Document</b></u></a></li></div>
                                <div className='hyperlink leftAligned' onClick={() => this.redirectPage('commoncredentials')}><li><u><b>Common Credentials</b></u></li></div>
                            </ul>
                            <h5 className='leftAligned'><b>UAT Testing & App Validation</b></h5>
                            <ul>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/ui-guide.pdf"} download><u><b>UI Guide</b></u></a></li></div>
                                <div className='hyperlink leftAligned'><li><a href={"/assets/netsclick/materials/uat-test-cases.xlsx"} download><u><b>UAT Test Cases</b></u></a></li></div>
                            </ul>
                        </div>
                    </Col>
                </FormGroup>
                <Query />


            </Layout>
        )
    }

}
